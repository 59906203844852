// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contacto-js": () => import("./../../../src/pages/contacto.js" /* webpackChunkName: "component---src-pages-contacto-js" */),
  "component---src-pages-empleo-js": () => import("./../../../src/pages/empleo.js" /* webpackChunkName: "component---src-pages-empleo-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-newsletter-js": () => import("./../../../src/pages/newsletter.js" /* webpackChunkName: "component---src-pages-newsletter-js" */),
  "component---src-pages-proyectos-js": () => import("./../../../src/pages/proyectos.js" /* webpackChunkName: "component---src-pages-proyectos-js" */),
  "component---src-pages-servicios-consultoria-y-estrategia-js": () => import("./../../../src/pages/servicios/consultoria-y-estrategia.js" /* webpackChunkName: "component---src-pages-servicios-consultoria-y-estrategia-js" */),
  "component---src-pages-servicios-index-js": () => import("./../../../src/pages/servicios/index.js" /* webpackChunkName: "component---src-pages-servicios-index-js" */),
  "component---src-pages-servicios-marketing-y-content-js": () => import("./../../../src/pages/servicios/marketing-y-content.js" /* webpackChunkName: "component---src-pages-servicios-marketing-y-content-js" */),
  "component---src-pages-servicios-web-aplicaciones-y-proyectos-digitales-js": () => import("./../../../src/pages/servicios/web-aplicaciones-y-proyectos-digitales.js" /* webpackChunkName: "component---src-pages-servicios-web-aplicaciones-y-proyectos-digitales-js" */),
  "component---src-templates-blog-js": () => import("./../../../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-politicas-js": () => import("./../../../src/templates/politicas.js" /* webpackChunkName: "component---src-templates-politicas-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-proyecto-js": () => import("./../../../src/templates/proyecto.js" /* webpackChunkName: "component---src-templates-proyecto-js" */)
}

