import React from "react"
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3"
import { AnimatePresence } from "framer-motion"
export const wrapRootElement = ({ element }) => {
  return (
    <GoogleReCaptchaProvider
      reCaptchaKey="6LcuauoaAAAAAIkbxzh-I4R48IvQ9JeLVyxLNXMO"
      scriptProps={{
        async: true, // optional, default to false,
        defer: true, // optional, default to false
      }}
    >
      <AnimatePresence exitBeforeEnter>{element}</AnimatePresence>
    </GoogleReCaptchaProvider>
  )
}
